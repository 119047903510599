import React from 'react'
import './ProductFeatures.css'
import { useRef } from 'react'
import { useState } from 'react'
import one from '../../../assets/one.png'
import two from '../../../assets/two.png'
import three from '../../../assets/Three.png'
import four from '../../../assets/4.png'
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";


const ProductFeatures = () => {

   const featuresRef = useRef(null);

   const scrollAmount = 300; // Adjust based on your card width

   const scrollLeft = () => {
     featuresRef.current.scrollBy({
       left: -scrollAmount,
       behavior: "smooth",
     });
   };

   const scrollRight = () => {
     featuresRef.current.scrollBy({
       left: scrollAmount,
       behavior: "smooth",
     });
   };


  return (
    <div className="prodFeatures">
      <h1>Product Features</h1>
      <div className="features">
        <FaArrowAltCircleLeft
          size={28}
          className="arrow left"
          onClick={scrollLeft}
        />
        <div className="card feat">
          <div className="card-body">
            <img src={one} alt="" />
            <h4 className="card-title">Eco-Friendly Initiatives</h4>
            <p className="card-text">
              Contribute to sustainability through eco-friendly packaging for
              tiffin services and environmentally-conscious homemaking
              strategies.
            </p>
          </div>
        </div>
        <FaArrowAltCircleRight
          size={28}
          className="arrow right"
          onClick={scrollRight}
        />
        <div className="card feat">
          <div className="card-body">
            <img src={two} alt="" />
            <h4 className="card-title">Peace of Mind</h4>
            <p className="card-text">
              Reclaim your time, knowing that your laundry, meals, and home are
              in the hands of professionals who care.
            </p>
          </div>
        </div>

        <div className="card feat">
          <div className="card-body">
            <img src={three} alt="" />
            <h4 className="card-title">Quality Assurance</h4>
            <p className="card-text">
              Trust in our commitment to excellence – from gourmet meals to
              homemaker services, we guarantee top-tier quality and
              satisfaction.
            </p>
          </div>
        </div>

        <div className="card feat">
          <div className="card-body">
            <img src={four} alt="" />
            <h4 className="card-title">Seamless Booking</h4>
            <p className="card-text">
              User-friendly app for convenient booking and scheduling across all
              services, allowing you to manage your preferences effortlessly.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductFeatures
