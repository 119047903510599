import React from 'react'
import './Services.css'
import tiffin from '../../../assets/work.png'
import chef from '../../../assets/Kitchen.png'
import laundry from '../../../assets/Laundry.jpeg'
import homemaker from '../../../assets/Glass.png'

const Services = () => {
  return (
    <div className="services">
      <div className="servRight">
        <div className="service-card sc1">
          <h2>Tiffin Service</h2>
          <p>
            Prepeat brings the joy of home-cooked meals to your doorstep.
            Whether you're looking for a healthy and nutritious meal or a
            delicious and comforting meal, Prep Eat has you covered.
          </p>
          <div className="serv-button">
            <a className="sc1-a1" href="">
              Book Now
            </a>
            <a className="sc1-a2" href="">
              Know more
            </a>
          </div>
        </div>
        <img className="" src={tiffin} alt="" />
      </div>

      <div className="servLeft">
        <img className="" src={chef} alt="" />
        <div className="service-card sc2">
          <h2>Chef Service</h2>
          <p>
            Prepeat brings the joy of home-cooked meals to your doorstep.
            Whether you're looking for a healthy and nutritious meal or a
            delicious and comforting meal, Prep Eat has you covered.
          </p>
          <div className="serv-button">
            <a className="sc2-a1" href="">
              Book Now
            </a>
            <a className="sc2-a2" href="">
              Know more
            </a>
          </div>
        </div>
      </div>

      <div className="servRight">
        <div className="service-card sc3">
          <h2>Laundry service</h2>
          <p>
            Prepeat pamper your clothes with expert care, leaving them fresh,
            crisp, and ready to conquer the day. Let us handle the dirty work
            while you enjoy the results – a wardrobe that exudes confidence and
            comfort.
          </p>
          <div className="serv-button">
            <a className="sc3-a1" href="">
              Book Now
            </a>
            <a className="sc3-a2" href="">
              Know more
            </a>
          </div>
        </div>
        <img className="" src={laundry} alt="" />
      </div>

      <div className="servLeft">
        <img className="homemaker-img" src={homemaker} alt="" />
        <div className="service-card sc4">
          <h2>Homemaker Service</h2>
          <p>
            Experience the art of homemaking with PrepEat. Our skilled
            homemakers transform your space into a heaven of order and comfort.
            Rediscover the joy of coming home to a perfectly managed sanctuary.
          </p>
          <div className="serv-button">
            <a className="sc4-a1" href="">
              Book Now
            </a>
            <a className="sc4-a2" href="">
              Know more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services
