import React from 'react'
import './Footer.css'
import appstore from '../../../assets/Footer-appstore.png'
import googleplay from '../../../assets/footer-googleplay.png'
import logo from '../../../assets/footer-logo.png'
import facebook from '../../../assets/facebook.png'
import insta from '../../../assets/insta.png'
import twiter from '../../../assets/X.png'
import utube from '../../../assets/utube.png'

const Footer = () => {
  return (
    <div className="footer">
      <div className="sec1">
        <div className="footerLeft">
          <h3>QUICK LINKS</h3>
          <ul>
            <li>Careers</li>
            <li>About Us</li>
            <li>Contact Us</li>
            <li>Privacy Policy</li>
            <li>Terms & Condition</li>
            <li>Cancellation and Refund Policy</li>
          </ul>
        </div>

        <div className="footer-mid">
          <img src={appstore} alt="" />
          <img src={googleplay} alt="" />
        </div>

        <div className="footerRight">
          <h3>REACH US</h3>
          <ul>
            <li>
              <a href=""></a>care@prepeat.in
            </li>
            <li>+91 9147094083</li>
          </ul>
        </div>
      </div>

      <div className="sec2">
        <div className="sec2-left">
          <img src={logo} alt="" />
        </div>

        <p>copyright &#169; 2024 PrepEat. All rights reserved.</p>

        <div className="sec2-right">
          <a href="https://www.facebook.com/prepeat.in">
            <img src={facebook} alt="" />
          </a>
          <a href="https://www.instagram.com/prepeat.in/">
            <img src={insta} alt="" />
          </a>
          <a href="https://x.com/i/flow/login?redirect_after_login=%2FPrepeat_in">
            <img src={twiter} alt="" />
          </a>
          <a href="https://www.youtube.com/@PrepEat">
            <img src={utube} alt="" />
          </a>
        </div>

        <div className="foot-end">
          <img src={appstore} alt="" />
          <img src={googleplay} alt="" />
        </div>

      </div>
    </div>
  );
}

export default Footer
