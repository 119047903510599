import React from 'react'
import './Header.css'

const Header = () => {
  return (
    <div className='chef-header'>
        <h1><span>Chef</span> Services</h1>
        <p>"Bring Culinary Excellence Home with a Professional Chef at Your Doorstep"</p>
      
    </div>
  )
}

export default Header
