import React from 'react'
import './Chef.css'
import Header from '../../Sections/ChefServicesSections/Header/Header'
const Chef = () => {
  return (
    <div>
      <Header/>
    </div>
  )
}

export default Chef
