import React,{useState} from 'react'
import './Login.css'
import pizza from '../../assets/LoginPage/login-pizza.png'
import { FcGoogle } from "react-icons/fc";
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";

const Login = () => {
     const [showPassword, setShowPassword] = useState(false);

     const togglePasswordVisibility = () => {
       setShowPassword(!showPassword);
     };

  return (
    <div className="loginPage">
      <div className="login-sec1">
        <img src={pizza} alt="" />
        <p>Get your favourite food with instant fast delivery</p>
      </div>

      <div className="login-sec2">
        <h2>Welcome to Prepeat</h2>
        <form className="login-form" action="">
          <input type="text" placeholder="Email or phone number" />

          <div className="password-input-container">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
            />
            <div className="eye-icon" onClick={togglePasswordVisibility}>
              {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
            </div>
          </div>
          <p className="forget-pass">Forgot password?</p>
          <button className="login-btn">Login</button>
          <hr />
          <button className="google-login-btn">
            <FcGoogle size={24} className="mx-2 google-icon" /> Or Login with
            Google
          </button>
          <p className="create-acc-p">
            Don't have an account?
            <span> Sign up now</span>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login
