import React from 'react'
import logo from '../../assets/Logo.png'
import './Navbar.css'
import { Link } from 'react-router-dom';

const Navbar = () => {

  return (
    <>
      <div className="nav">
        <div className="navContainer">
          <div className="navContent">
            <div className="nav-left">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="nav-middle">
              <ul className="nav-menu active">
                <Link to="/tiffin">Tiffin Service</Link>
                <Link to="/chef">Chef Service</Link>
                <li>Laundry Service</li>
                <li>Homemaker Service</li>
              </ul>
            </div>
            <div className="nav-right">
              <Link to="/login">
                <button className="btnn">Get Started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar
