import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Footer from "./components/Footer/Footer/Footer";
import Tiffin from "./Pages/TiffinService/Tiffin";
import FAQs from "./components/FAQs/FAQs";
import Login from "./components/Login/Login";
import Chef from "./Pages/Chef Service/Chef";

const App = () => {
  return (
    <>
      <div className="app">
        <Router>
          <MainContent />
        </Router>
      </div>
    </>
  );
};

const MainContent = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/login" && <Navbar />}

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/tiffin" element={<Tiffin />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/chef" element={<Chef />}></Route>
      </Routes>

      {location.pathname !== "/login" && (
        <>
          <FAQs />
          <Footer />
        </>
      )}
    </>
  );
};

export default App;
